import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import top_image from "../../images/talent_image.png";
import styles from "./talents.module.css";
import woman_image from "../../images/woman.png";
import coworkers_image from "../../images/coworkers.png";
import white_logo from "../../images/GoTalents-Logo-16.png";
import { createTalent } from "../../redux/actions/actions";
import validate from "./validation/validation";
import spanish_video from "../../videos/spanish_video.mp4";
import english_video from "../../videos/english_video.mp4";
import Swal from "sweetalert2";

const Talents = () => {
  const dispatch = useDispatch();
  const isEnglish = useSelector((state) => state.isEnglish);
  const [cvFile, setCvFile] = useState(null);
  const [languageFile, setLanguageFile] = useState(null);
  const [talent, setTalent] = useState({
    name: "",
    lastname: "",
    email: "",
    phone: "",
    position: "",
    cvFile: "",
    languageFile: "",
  });
  const [error, setError] = useState({
    name: "",
    lastname: "",
    email: "",
    phone: "",
    position: "",
    cvFile: "",
    languageFile: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTalent({
      ...talent,
      [name]: value,
    });
    if (name === "cvFile") {
      setCvFile(event.target.files[0]);
    } else if (name === "languageFile") {
      setLanguageFile(event.target.files[0]);
    }
    // Llama a validate después de que se actualiza el estado del talent y el error
    validate({ ...talent, [name]: value }, name, error, setError);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    validate({ ...talent }, error, setError);
    const hasErrors = Object.values(error).some(
      (errorMessage) => errorMessage !== ""
    );
    if (!hasErrors) {
      Swal.fire({
        title: isEnglish ? "Submit Form?" : "¿Enviar formulario?",
        text: isEnglish
          ? "Are you sure you want to submit the form?"
          : "¿Estás seguro de que quieres enviar el formulario?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: isEnglish ? "Yes, submit" : "Sí, enviar",
      }).then((result) => {
        if (result.isConfirmed) {
          const talentData = {
            ...talent,
            cvFile,
            languageFile,
          };

          dispatch(createTalent(talentData))
            .then(() => {
              Swal.fire({
                title: isEnglish ? "Success!" : "¡Éxito!",
                text: isEnglish
                  ? "Thank you. We will contact you shortly."
                  : "Muchas gracias. Nos pondremos en contacto a la brevedad.",
                icon: "success",
              }).then(() => {
                // Limpiar los campos después del éxito
                setTalent({
                  name: "",
                  lastname: "",
                  email: "",
                  phone: "",
                  position: "",
                  cvFile: "", // Cambiar null por ""
                  languageFile: "", // Cambiar null por ""
                });
                setError({
                  name: "",
                  lastname: "",
                  email: "",
                  phone: "",
                  position: "",
                  cvFile: "",
                  languageFile: "",
                });
              });
            })
            .catch((error) => {
              console.error("Ups, no hemos podido enviar tu solicitud:", error);
              Swal.fire({
                icon: "error",
                title: isEnglish
                  ? "Oops! We have made a mistake."
                  : "¡Ups! Hemos cometido un error.",
                text: isEnglish
                  ? "Please try again. If the error persists, or contact us contact@gotalentsglobal.com"
                  : "Por favor, intentalo nuevamente. Si el error persiste comunicate con nosotros: contact@gotalentsglobal.com",
              });
            });
        }
      });
    }
  };

  const handleJoinOurTeamClick = () => {
    const workWithUsTitle = document.getElementById("workWithUsTitle");
    workWithUsTitle.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.container}>
      <img src={top_image} alt="top_image" className={styles.top_image} />

      {isEnglish ? (
        <>
          <div className={styles.title_body_container}>
            <h1 className={styles.title}>WHERE YOUR CAREER SOARS!</h1>
            <p className={styles.paragraph}>
              Our personalized approach and market expertise enable us to
              provide you with a high-quality and efficient service.
            </p>

            <div>
              <img
                src={white_logo}
                alt="white_logo"
                className={styles.white_logo}
              />
              <h2 className={styles.secondTitle}>
                Why
                <span className={styles.bold}> join our family?</span>
              </h2>

              <div className={styles.cardsContainer}>
                <div className={styles.card}>
                  <h2 className={styles.subtitle}>
                    Access to exclusive job opportunities
                  </h2>
                  <p className={styles.paragraphCard}>
                    Thanks to our extensive network of contacts with companies,
                    we can provide you access to job openings that are not
                    available to the general public or listed on traditional job
                    search websites.
                  </p>
                </div>
                <div className={styles.card}>
                  <h2 className={styles.subtitle}>Specialized guidance</h2>
                  <p className={styles.paragraphCard}>
                    Professional guidance to enhance your profile and increase
                    your chances of success.
                  </p>
                </div>
                <div className={styles.card}>
                  <h2 className={styles.subtitle}>Greater visibility</h2>
                  <p className={styles.paragraphCard}>
                    Your profile will be professionally presented to hiring
                    companies, enhancing your visibility and providing you with
                    a competitive edge over other candidates.{" "}
                  </p>
                </div>
                <div className={styles.card}>
                  <h2 className={styles.subtitle}>Time and effort savings</h2>
                  <p className={styles.paragraphCard}>
                    We take care of finding job opportunities that match your
                    profile, saving you time and effort in the process.
                  </p>
                </div>
                <div className={styles.card}>
                  <h2 className={styles.subtitle}>Confidentiality</h2>
                  <p className={styles.paragraphCard}>
                    We respect your privacy and confidentiality at all times.
                    Your personal and professional information will be treated
                    with the utmost discretion.
                  </p>
                </div>
              </div>

              <div className={styles.featuredCard}>
                <p className={styles.featuredCardSubtitle}>
                  <span className={styles.bold}>Go Talents</span> provides you
                  with all the necessary tools to enable you to perform the job
                  accorging to the company's requirements.
                </p>
                <button
                  className={styles.button}
                  onClick={handleJoinOurTeamClick}
                >
                  Join Our Team
                </button>
              </div>

              <div className={styles.imagesContainer}>
                <img
                  src={woman_image}
                  alt="woman_image"
                  className={styles.woman_image}
                />
                <img
                  src={coworkers_image}
                  alt="coworkers_image"
                  className={styles.coworkers_image}
                />
              </div>
            </div>
          </div>
          <div className={styles.secondCardsContainer}>
            <div className={styles.card}>
              <h2 className={styles.subtitle}>Professional Development</h2>
              <p className={styles.paragraphCard}>
                Continuous learning is fundamental to our culture. Benefit from
                ongoing training programs, mentoring opportunities, and
                resources to enhance your skills and stay at the forefront of
                your field.
              </p>
            </div>
            <div className={styles.card}>
              <h2 className={styles.subtitle}>Best salaries</h2>
              <p className={styles.paragraphCard}>2 days off.</p>
            </div>
            <div className={styles.card}>
              <h2 className={styles.subtitle}>Work-life balance</h2>
              <p className={styles.paragraphCard}>
                Continuous learning is fundamental to our culture. Benefit from
                ongoing training programs, mentoring opportunities, and
                resources to enhance your skills and stay at the forefront of
                your field.
              </p>
            </div>
          </div>
          <div className={styles.thirdContainer}>
            <div className={styles.featuredContainer}>
              <div className={styles.title_body_container}>
                <h2 className={styles.thirdTitle}>
                  How does our <br />
                  <span className={styles.bold}> process recruitment </span>
                  work?
                </h2>
                <video className={styles.video} controls>
                  <source src={english_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>

            <div className={styles.fourthContainer}>
              <h2 className={styles.formTitle} id="workWithUsTitle">
                Work with us
              </h2>

              <form
                onSubmit={handleSubmit}
                className={styles.form}
                action="/talent"
                method="POST"
                encType="multipart/form-data"
              >
                <label className={styles.label}>Name:</label>
                <input
                  value={talent.name}
                  name="name"
                  onChange={handleChange}
                  type="text"
                  placeholder="My name"
                />
                <label className={styles.formErrors}>{error.name}</label>

                <label className={styles.label}>Last name:</label>
                <input
                  value={talent.lastname}
                  name="lastname"
                  onChange={handleChange}
                  type="text"
                  placeholder="My lastname"
                />
                <label className={styles.formErrors}>{error.lastname}</label>

                <label className={styles.label}>Position:</label>
                 <select
                  name="position"
                  value={talent.position}
                  onChange={handleChange}
                  className={styles.select}
                >
                  <option value="" disabled>
                    Select a career
                  </option>
                  <option value="Accounting Auditing">
                    Accounting Auditing
                  </option>
                  <option value="Actuarial Science">Actuarial Science</option>
                  <option value="Advertising">Advertising</option>
                  <option value="Aerospace Engineering">
                    Aerospace Engineering
                  </option>
                  <option value="Agribusiness">Agribusiness</option>
                  <option value="Agricultural Administration">
                    Agricultural Administration
                  </option>
                  <option value="Agricultural Engineering">
                    Agricultural Engineering
                  </option>
                  <option value="Agronomy">Agronomy</option>
                  <option value="Aquaculture">Aquaculture</option>
                  <option value="Anthropology">Anthropology</option>
                  <option value="Architecture">Architecture</option>
                  <option value="Archaeology">Archaeology</option>
                  <option value="Astronomy">Astronomy</option>
                  <option value="Audiovisual Communication">
                    Audiovisual Communication
                  </option>
                  <option value="Bacteriology">Bacteriology</option>
                  <option value="Biochemistry">Biochemistry</option>
                  <option value="Bioengineering">Bioengineering</option>
                  <option value="Biology">Biology</option>
                  <option value="Biophysics">Biophysics</option>
                  <option value="Biotechnology">Biotechnology</option>
                  <option value="Business Administration">
                    Business Administration
                  </option>
                  <option value="Call Center">Call Center</option>
                  <option value="Cartography">Cartography</option>
                  <option value="Certified Accountant">
                    Certified Accountant
                  </option>
                  <option value="Civil Engineering">Civil Engineering</option>
                  <option value="Commercial Engineering">
                    Commercial Engineering
                  </option>
                  <option value="Communications">Communications</option>
                  <option value="Computer Engineering">
                    Computer Engineering
                  </option>
                  <option value="Computer Science">Computer Science</option>
                  <option value="Construction Engineering">
                    Construction Engineering
                  </option>
                  <option value="Customs Brokerage">Customs Brokerage</option>
                  <option value="Customs Brokerage">Customs Brokerage</option>
                  <option value="Dentistry">Dentistry</option>
                  <option value="Dental Laboratory Technician">
                    Dental Laboratory Technician
                  </option>
                  <option value="Design">Design</option>
                  <option value="Economics">Economics</option>
                  <option value="Education">Education</option>
                  <option value="Education Sciences">Education Sciences</option>
                  <option value="Electrical Engineering">
                    Electrical Engineering
                  </option>
                  <option value="Electronics Engineering">
                    Electronics Engineering
                  </option>
                  <option value="Electrical Engineering">
                    Electrical Engineering
                  </option>
                  <option value="Electronics Engineering">
                    Electronics Engineering
                  </option>
                  <option value="Environmental Engineering">
                    Environmental Engineering
                  </option>
                  <option value="Environmental Science">
                    Environmental Science
                  </option>
                  <option value="Finance">Finance</option>
                  <option value="Fine Arts">Fine Arts</option>
                  <option value="Food Engineering">Food Engineering</option>
                  <option value="Forestry Engineering">
                    Forestry Engineering
                  </option>
                  <option value="Geography">Geography</option>
                  <option value="Geology, Geomatics, Surveying">
                    Geology, Geomatics, Surveying
                  </option>
                  <option value="Geophysics">Geophysics</option>
                  <option value="History">History</option>
                  <option value="Hospitality">Hospitality</option>
                  <option value="Human Resources, Industrial Relations">
                    Human Resources, Industrial Relations
                  </option>
                  <option value="Hydraulics">Hydraulics</option>
                  <option value="Industrial Engineering">
                    Industrial Engineering
                  </option>
                  <option value="Industrial Safety">Industrial Safety</option>
                  <option value="Information Sciences">
                    Information Sciences
                  </option>
                  <option value="International Finance">
                    International Finance
                  </option>
                  <option value="International Legal Advisory">
                    International Legal Advisory
                  </option>
                  <option value="International Market">
                    International Market
                  </option>
                  <option value="International Procurement, Importing">
                    International Procurement, Importing
                  </option>
                  <option value="International Relations">
                    International Relations
                  </option>
                  <option value="Interpreter">Interpreter</option>
                  <option value="Journalism">Journalism</option>
                  <option value="Kinesiology">Kinesiology</option>
                  <option value="Laboratory Sciences">
                    Laboratory Sciences
                  </option>
                  <option value="Law">Law</option>
                  <option value="Library Science">Library Science</option>
                  <option value="Marketing, Commercialization">
                    Marketing, Commercialization
                  </option>
                  <option value="Master Builder">Master Builder</option>
                  <option value="Mathematics">Mathematics</option>
                  <option value="Mathematical Engineering">
                    Mathematical Engineering
                  </option>
                  <option value="Mechanical, Metallurgical Engineering">
                    Mechanical, Metallurgical Engineering
                  </option>
                  <option value="Medicine">Medicine</option>
                  <option value="Medical Technology">Medical Technology</option>
                  <option value="Microbiology">Microbiology</option>
                  <option value="Mining Engineering">Mining Engineering</option>
                  <option value="Mining, Oil, Gas">Mining, Oil, Gas</option>
                  <option value="Music">Music</option>
                  <option value="Naval Engineering">Naval Engineering</option>
                  <option value="Notary Public">Notary Public</option>
                  <option value="Nuclear Engineering">
                    Nuclear Engineering
                  </option>
                  <option value="Nursing">Nursing</option>
                  <option value="Nutrition">Nutrition</option>
                  <option value="Oceanography">Oceanography</option>
                  <option value="Other">Other</option>
                  <option value="Pharmacy">Pharmacy</option>
                  <option value="Philosophy">Philosophy</option>
                  <option value="Physical Sciences">Physical Sciences</option>
                  <option value="Physical Therapy">Physical Therapy</option>
                  <option value="Physics">Physics</option>
                  <option value="Political Science">Political Science</option>
                  <option value="Programming">Programming</option>
                  <option value="Psychology">Psychology</option>
                  <option value="Public Administration">
                    Public Administration
                  </option>
                  <option value="Public Relations">Public Relations</option>
                  <option value="Real Estate">Real Estate</option>
                  <option value="Retail">Retail</option>
                  <option value="Secretarial Studies">
                    Secretarial Studies
                  </option>
                  <option value="Sociology">Sociology</option>
                  <option value="Sound Engineering">Sound Engineering</option>
                  <option value="Speech Therapy">Speech Therapy</option>
                  <option value="Statistics">Statistics</option>
                  <option value="Surveying">Surveying</option>
                  <option value="Systems Analysis">Systems Analysis</option>
                  <option value="Systems Engineering">
                    Systems Engineering
                  </option>
                  <option value="Telecommunications Engineering">
                    Telecommunications Engineering
                  </option>
                  <option value="Total Quality Management">
                    Total Quality Management
                  </option>
                  <option value="Transportation Engineering">
                    Transportation Engineering
                  </option>
                  <option value="Transportation">Transportation</option>
                  <option value="Urban Planning">Urban Planning</option>
                  <option value="Veterinary">Veterinary</option>
                  <option value="Water Resources Engineering">
                    Water Resources Engineering
                  </option>
                  <option value="Web Design">Web Design</option>
                </select>

                <label className={styles.label}>E-mail:</label>
                <input
                  value={talent.email}
                  name="email"
                  onChange={handleChange}
                  type="text"
                  placeholder="E.g.: myname@example.com"
                />
                <label className={styles.formErrors}>{error.email}</label>

                <label className={styles.label}>Phone number:</label>
                <input
                  value={talent.phone}
                  name="phone"
                  onChange={handleChange}
                  type="text"
                  placeholder="Contact phone number"
                />
                <label className={styles.formErrors}>{error.phone}</label>

                <label className={styles.label}>Currículum Vitae:</label>
                <p className={styles.text}>Pdf format</p>
                <div className={styles.fileButton}>
                  <input
                    value={talent.cvFile}
                    name="cvFile"
                    onChange={handleChange}
                    type="file"
                    accept=".pdf"
                  />
                </div>
                <label className={styles.formErrors}>{error.cvFile}</label>

                <label className={styles.label}>Language Certificate:</label>
                <p className={styles.text}>(Optional)</p>
                <p className={styles.text}>Pdf format</p>
                <div className={styles.fileButton}>
                  <input
                    value={talent.languageFile}
                    name="languageFile"
                    onChange={handleChange}
                    type="file"
                    accept=".pdf"
                  />
                </div>
                <label className={styles.formErrors}>
                  {error.languageFile}
                </label>
                <button
                  disabled={
                    !talent.name ||
                    !talent.lastname ||
                    !talent.email ||
                    !talent.phone ||
                    !talent.position ||
                    !!error.name ||
                    !!error.lastname ||
                    !!error.email ||
                    !!error.phone ||
                    !!error.position ||
                    !cvFile
                  }
                  type="submit"
                  className={styles.buttonSubmit}
                >
                  {" "}
                  Submit
                </button>
              </form>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.title_body_container}>
            <h1 className={styles.title}>¡DONDE TU CARRERA DESPEGA!</h1>
            <p className={styles.paragraph}>
              Nuestro enfoque personalizado y nuestra experiencia en el mercado
              laboral nos permiten ofrecerte un servicio de calidad y eficiente.
            </p>

            <div>
              <img
                src={white_logo}
                alt="white_logo"
                className={styles.white_logo}
              />
              <h2 className={styles.secondTitle}>
                ¿Por qué
                <span className={styles.bold}> unirte a nuestra familia</span>?
              </h2>

              <div className={styles.cardsContainer}>
                <div className={styles.card}>
                  <h2 className={styles.subtitle}>
                    Acceso a oportunidades laborales exclusivas
                  </h2>
                  <p className={styles.paragraphCard}>
                    Gracias a nuestra amplia red de contactos con empresas,
                    podemos brindarte acceso a vacantes que no se encuentran
                    disponibles al público en general ni se encuentran en los
                    sitios de búsqueda de empleo tradicionales.
                  </p>
                </div>
                <div className={styles.card}>
                  <h2 className={styles.subtitle}>
                    Asesoramiento especializado
                  </h2>
                  <p className={styles.paragraphCard}>
                    Asesoramiento profesional para mejorar tu perfil y aumentar
                    las posibilidades de éxito.
                  </p>
                </div>
                <div className={styles.card}>
                  <h2 className={styles.subtitle}>Mayor visibilidad</h2>
                  <p className={styles.paragraphCard}>
                    Tu perfil será presentado de manera profesional a las
                    empresas empleadoras. Esto aumentará tu visibilidad y te
                    dará una ventaja competitiva frente a otros candidatos.
                  </p>
                </div>
                <div className={styles.card}>
                  <h2 className={styles.subtitle}>
                    Ahorro de tiempo y esfuerzo
                  </h2>
                  <p className={styles.paragraphCard}>
                    Nos encargamos de buscar oportunidades laborales que se
                    ajusten a tu perfil, ahorrándote tiempo y esfuerzo en el
                    proceso.
                  </p>
                </div>
                <div className={styles.card}>
                  <h2 className={styles.subtitle}>Confidencialidad</h2>
                  <p className={styles.paragraphCard}>
                    Respetamos tu privacidad y confidencialidad en todo momento.
                    Tu información personal y profesional será tratada con la
                    máxima discreción.
                  </p>
                </div>
              </div>

              <div className={styles.featuredCard}>
                <p className={styles.featuredCardSubtitle}>
                  <span className={styles.bold}>Go Talents</span> te entrega
                  todas las herramientas necesarias para que puedas realizar el
                  trabajo según lo exigido por la empresa.
                </p>
                <button
                  className={styles.button}
                  onClick={handleJoinOurTeamClick}
                >
                  Unete a nuestro equipo
                </button>
              </div>

              <div className={styles.imagesContainer}>
                <img
                  src={woman_image}
                  alt="woman_image"
                  className={styles.woman_image}
                />
                <img
                  src={coworkers_image}
                  alt="coworkers_image"
                  className={styles.coworkers_image}
                />
              </div>
            </div>
          </div>
          <div className={styles.secondCardsContainer}>
            <div className={styles.card}>
              <h2 className={styles.subtitle}>Desarrollo profesional</h2>
              <p className={styles.paragraphCard}>
                El aprendizaje continuo es fundamental en nuestra cultura.
                Benefíciate de programas de formación en curso, oportunidades de
                mentoría y recursos para mejorar tus habilidades y mantenerte a
                la vanguardia en tu campo.
              </p>
            </div>
            <div className={styles.card}>
              <h2 className={styles.subtitle}>Los mejores salarios</h2>
              <p className={styles.paragraphCard}>2 días libres</p>
            </div>
            <div className={styles.card}>
              <h2 className={styles.subtitle}>
                Equilibrio entre trabajo y vida
              </h2>
              <p className={styles.paragraphCard}>
                Disfruta de flexibilidad y la posibilidad de trabajar de forma
                remota, asegurándote de poder destacar en tu vida profesional
                sin comprometer tu bienestar personal.
              </p>
            </div>
          </div>
          <div className={styles.thirdContainer}>
            <div className={styles.featuredContainer}>
              <div className={styles.title_body_container}>
                <h2 className={styles.thirdTitle}>
                  ¿Cómo funciona nuestro <br />
                  <span className={styles.bold}>proceso de reclutamiento</span>?
                </h2>
                <video className={styles.video} controls>
                  <source src={spanish_video} type="video/mp4" />
                  Su navegador no soporta la etiqueta de vídeo.
                </video>
              </div>
            </div>

            <div className={styles.fourthContainer}>
              <h2 className={styles.formTitle} id="workWithUsTitle">
                Contactanos
              </h2>

              <form
                onSubmit={handleSubmit}
                className={styles.form}
                action="/talent"
                method="POST"
                encType="multipart/form-data"
              >
                <label className={styles.label}>Nombre:</label>
                <input
                  name="name"
                  value={talent.name}
                  onChange={handleChange}
                  type="text"
                  placeholder="Mi nombre"
                />
                <label className={styles.formErrors}>{error.name}</label>

                <label className={styles.label}>Apellido:</label>
                <input
                  name="lastname"
                  value={talent.lastname}
                  onChange={handleChange}
                  type="text"
                  placeholder="Mi apellido"
                />
                <label className={styles.formErrors}>{error.lastname}</label>

                <label className={styles.label}>Carrera:</label>
                  <select
                  name="position"
                  value={talent.position}
                  onChange={handleChange}
                  className={styles.select}
                >
                  <option value="" disabled>
                    Elegí una opción
                  </option>
                  <option value="Abogacía, Derecho, Leyes">
                    Abogacía, Derecho, Leyes
                  </option>
                  <option value="Actuaría">Actuaría</option>
                  <option value="Acuicultura">Acuicultura</option>
                  <option value="Adm. de Empresas">Adm. de Empresas</option>
                  <option value="Adm. y Gestión Pública">
                    Adm. y Gestión Pública
                  </option>
                  <option value="Administración agropecuaria">
                    Administración agropecuaria
                  </option>
                  <option value="Agrimensor">Agrimensor</option>
                  <option value="Agronegocios">Agronegocios</option>
                  <option value="Antropología">Antropología</option>
                  <option value="Análisis de Sistemas">
                    Análisis de Sistemas
                  </option>
                  <option value="Apoderado Aduanal">Apoderado Aduanal</option>
                  <option value="Arqueología">Arqueología</option>
                  <option value="Arquitectura">Arquitectura</option>
                  <option value="Asesoría Legal Internacional">
                    Asesoría Legal Internacional
                  </option>
                  <option value="Asesoría en Comercio Exterior">
                    Asesoría en Comercio Exterior
                  </option>
                  <option value="Asistente de tráfico">
                    Asistente de tráfico
                  </option>
                  <option value="Astronomía">Astronomía</option>
                  <option value="Bachiller">Bachiller</option>
                  <option value="Bacteriología">Bacteriología</option>
                  <option value="Bellas Artes">Bellas Artes</option>
                  <option value="Bibliotecología">Bibliotecología</option>
                  <option value="Biofísica">Biofísica</option>
                  <option value="Bioingeniería">Bioingeniería</option>
                  <option value="Biología">Biología</option>
                  <option value="Bioquímica">Bioquímica</option>
                  <option value="Biotecnología">Biotecnología</option>
                  <option value="Call Center">Call Center</option>
                  <option value="Capacitación Comercio Exterior">
                    Capacitación Comercio Exterior
                  </option>
                  <option value="Cartografía">Cartografía</option>
                  <option value="Ciencias Físicas">Ciencias Físicas</option>
                  <option value="Ciencias Políticas">Ciencias Políticas</option>
                  <option value="Ciencias de la Educación">
                    Ciencias de la Educación
                  </option>
                  <option value="Ciencias del Ejercicio, Educación Física">
                    Ciencias del Ejercicio, Educación Física
                  </option>
                  <option value="Comercio Int., Ext.">
                    Comercio Int., Ext.
                  </option>
                  <option value="Compras Internacionales, Importación">
                    Compras Internacionales, Importación
                  </option>
                  <option value="Computación, Informática">
                    Computación, Informática
                  </option>
                  <option value="Comunicación Audiovisual">
                    Comunicación Audiovisual
                  </option>
                  <option value="Comunicación Visual">
                    Comunicación Visual
                  </option>
                  <option value="Construcción, Obras Civiles">
                    Construcción, Obras Civiles
                  </option>
                  <option value="Consultorías Comercio Exterior">
                    Consultorías Comercio Exterior
                  </option>
                  <option value="Contabilidad Auditoría">
                    Contabilidad Auditoría
                  </option>
                  <option value="Dibujo Técnico">Dibujo Técnico</option>
                  <option value="Diseño Gráfico">Diseño Gráfico</option>
                  <option value="Diseño Industrial">Diseño Industrial</option>
                  <option value="Diseño Web">Diseño Web</option>
                  <option value="Diseño de Imagen y Sonido">
                    Diseño de Imagen y Sonido
                  </option>
                  <option value="Diseño de Vestuario, Textil, Modas">
                    Diseño de Vestuario, Textil, Modas
                  </option>
                  <option value="Ecología">Ecología</option>
                  <option value="Economía">Economía</option>
                  <option value="Educación">Educación</option>
                  <option value="Electricidad">Electricidad</option>
                  <option value="Electrónica">Electrónica</option>
                  <option value="Enfermería">Enfermería</option>
                  <option value="Enología">Enología</option>
                  <option value="Escribanía">Escribanía</option>
                  <option value="Estadística">Estadística</option>
                  <option value="Farmacia">Farmacia</option>
                  <option value="Filosofía">Filosofía</option>
                  <option value="Finanzas">Finanzas</option>
                  <option value="Finanzas Internacionales">
                    Finanzas Internacionales
                  </option>
                  <option value="Fisioterapia">Fisioterapia</option>
                  <option value="Fonoaudiología">Fonoaudiología</option>
                  <option value="Fotografía">Fotografía</option>
                  <option value="Gastronomía, Cocina">
                    Gastronomía, Cocina
                  </option>
                  <option value="Geofísica">Geofísica</option>
                  <option value="Geografía">Geografía</option>
                  <option value="Geología, Geomensura, Topografía">
                    Geología, Geomensura, Topografía
                  </option>
                  <option value="Hidráulica">Hidráulica</option>
                  <option value="Histopatología">Histopatología</option>
                  <option value="Historia">Historia</option>
                  <option value="Hotelería">Hotelería</option>
                  <option value="Ingeniería">Ingeniería</option>
                  <option value="Ing. Aeroespacial">Ing. Aeroespacial</option>
                  <option value="Ing. Agropecuario">Ing. Agropecuario</option>
                  <option value="Ing. Agrónomo">Ing. Agrónomo</option>
                  <option value="Ing. Alimentos">Ing. Alimentos</option>
                  <option value="Ing. Ambiental">Ing. Ambiental</option>
                  <option value="Ing. Comercial">Ing. Comercial</option>
                  <option value="Ing. Electrónica">Ing. Electrónica</option>
                  <option value="Ing. Eléctrica">Ing. Eléctrica</option>
                  <option value="Ing. Forestal">Ing. Forestal</option>
                  <option value="Ing. Hidráulica">Ing. Hidráulica</option>
                  <option value="Ing. Industrial">Ing. Industrial</option>
                  <option value="Ing. Informática">Ing. Informática</option>
                  <option value="Ing. Matemática">Ing. Matemática</option>
                  <option value="Ing. Mecánica, Metalúrgica">
                    Ing. Mecánica, Metalúrgica
                  </option>
                  <option value="Ing. Naval">Ing. Naval</option>
                  <option value="Ing. Nuclear">Ing. Nuclear</option>
                  <option value="Ing. Obras Civiles, Construcción">
                    Ing. Obras Civiles, Construcción
                  </option>
                  <option value="Ing. Pesquera, Cultivos Marinos">
                    Ing. Pesquera, Cultivos Marinos
                  </option>
                  <option value="Ing. Petróleo">Ing. Petróleo</option>
                  <option value="Ing. Química">Ing. Química</option>
                  <option value="Ing. Recursos Hídricos">
                    Ing. Recursos Hídricos
                  </option>
                  <option value="Ing. Sonido">Ing. Sonido</option>
                  <option value="Ing. Telecomunicaciones">
                    Ing. Telecomunicaciones
                  </option>
                  <option value="Ing. Transporte">Ing. Transporte</option>
                  <option value="Ing. en Materiales">Ing. en Materiales</option>
                  <option value="Ing. en Minas">Ing. en Minas</option>
                  <option value="Ing. en Sistemas">Ing. en Sistemas</option>
                  <option value="Ingeniero en Construcción">
                    Ingeniero en Construcción
                  </option>
                  <option value="Ingeniero Vial">Ingeniero Vial</option>
                  <option value="Intérprete">Intérprete</option>
                  <option value="Kinesiología">Kinesiología</option>
                  <option value="Laboratorio (Mecánica) Dental">
                    Laboratorio (Mecánica) Dental
                  </option>
                  <option value="Literatura">Literatura</option>
                  <option value="Maestro Mayor de Obras">
                    Maestro Mayor de Obras
                  </option>
                  <option value="Marketing, Comercialización">
                    Marketing, Comercialización
                  </option>
                  <option value="Matemáticas">Matemáticas</option>
                  <option value="Mecánica, Metalúrgica">
                    Mecánica, Metalúrgica
                  </option>
                  <option value="Medicina">Medicina</option>
                  <option value="Medio Ambiente">Medio Ambiente</option>
                  <option value="Mercado Internacional">
                    Mercado Internacional
                  </option>
                  <option value="Microbiología">Microbiología</option>
                  <option value="Minería, Petróleo, Gas">
                    Minería, Petróleo, Gas
                  </option>
                  <option value="Música">Música</option>
                  <option value="Nutrición">Nutrición</option>
                  <option value="Oceanografía">Oceanografía</option>
                  <option value="Odontología">Odontología</option>
                  <option value="Organización Industrial">
                    Organización Industrial
                  </option>
                  <option value="Otra">Otra</option>
                  <option value="Paisajismo">Paisajismo</option>
                  <option value="Periodismo">Periodismo</option>
                  <option value="Perito Mercantil">Perito Mercantil</option>
                  <option value="Procesos, Calidad Total">
                    Procesos, Calidad Total
                  </option>
                  <option value="Programación">Programación</option>
                  <option value="Psicología">Psicología</option>
                  <option value="Psicopedagogía">Psicopedagogía</option>
                  <option value="Publicidad">Publicidad</option>
                  <option value="Química">Química</option>
                  <option value="Químico Farmacéutico">
                    Químico Farmacéutico
                  </option>
                  <option value="Recursos Humanos, Relaciones Industriales">
                    Recursos Humanos, Relaciones Industriales
                  </option>
                  <option value="Relaciones Internacionales">
                    Relaciones Internacionales
                  </option>
                  <option value="Relaciones Públicas">
                    Relaciones Públicas
                  </option>
                  <option value="Salud">Salud</option>
                  <option value="Secretariado">Secretariado</option>
                  <option value="Seguridad Industrial">
                    Seguridad Industrial
                  </option>
                  <option value="Seguros">Seguros</option>
                  <option value="Sociología">Sociología</option>
                  <option value="Técnico">Técnico</option>
                  <option value="Tecnología Médica, Laboratorio">
                    Tecnología Médica, Laboratorio
                  </option>
                  <option value="Tecnología de Alimentos">
                    Tecnología de Alimentos
                  </option>
                  <option value="Tecnologías de la Información">
                    Tecnologías de la Información
                  </option>
                  <option value="Telecomunicaciones">Telecomunicaciones</option>
                  <option value="Terapia Ocupacional">
                    Terapia Ocupacional
                  </option>
                  <option value="Trabajo Social">Trabajo Social</option>
                  <option value="Transporte">Transporte</option>
                  <option value="Turismo">Turismo</option>
                  <option value="Ventas Internacionales, Exportación">
                    Ventas Internacionales, Exportación
                  </option>
                  <option value="Veterinaria">Veterinaria</option>
                </select>

                <label className={styles.label}>Correo electrónico:</label>
                <input
                  name="email"
                  value={talent.email}
                  onChange={handleChange}
                  type="text"
                  placeholder="Ej: micorreo@ejemplo.com"
                />
                <label className={styles.formErrors}>{error.email}</label>

                <label className={styles.label}>Teléfono:</label>
                <input
                  name="phone"
                  value={talent.phone}
                  onChange={handleChange}
                  type="text"
                  placeholder="Teléfono de contacto"
                />
                <label className={styles.formErrors}>{error.phone}</label>

                <label className={styles.label}>Currículum Vitae:</label>
                <p className={styles.text}>Formato .pdf</p>
                <div className={styles.fileButton}>
                  <input
                    value={talent.cvFile}
                    name="cvFile"
                    onChange={handleChange}
                    type="file"
                    accept=".pdf"
                  />
                </div>
                <label className={styles.formErrors}>{error.cvFile}</label>

                <label className={styles.label}>Certificado de Idioma:</label>
                <p className={styles.text}>(Opcional)</p>
                <p className={styles.text}>Formato .pdf</p>
                <div className={styles.fileButton}>
                  <input
                    value={talent.languageFile}
                    name="languageFile"
                    onChange={handleChange}
                    type="file"
                    accept=".pdf"
                  />
                </div>
                <label className={styles.formErrors}>
                  {error.languageFile}
                </label>
                <button
                  disabled={
                    !talent.name ||
                    !talent.lastname ||
                    !talent.email ||
                    !talent.phone ||
                    !talent.position ||
                    !!error.name ||
                    !!error.lastname ||
                    !!error.email ||
                    !!error.phone ||
                    !!error.position ||
                    !cvFile
                  }
                  type="submit"
                  className={styles.buttonSubmit}
                >
                  {" "}
                  Enviar
                </button>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Talents;
